/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Breadcrumb,
  Button,
  Descriptions,
  Empty,
  Popconfirm,
  Tag,
  Tooltip,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { HomeOutlined, RollbackOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import currencyFormatter from 'currency-formatter';
import { MdApproval, MdKeyboardBackspace } from 'react-icons/md';
import { Container, Title } from './styles';

import { Loading } from '../../components/Loading';
import { api } from '../../services/api';
import { getOrderCorrectionStatusColor } from '../../utils/getOrderCorrectionStatusColor';
import { getOrderCorrectionStatus } from '../../utils/getOrderCorrectionStatus';
import { useAuth } from '../../hooks/AuthContext';
import { getOrderPaymentStatusColor } from '../../utils/getOrderPaymentStatusColor';
import { getOrderPaymentStatus } from '../../utils/getOrderPaymentStatus';
import { ModalQuestionFiles } from './ModalQuestionFiles';
import { ModalProcuramentTerm } from './ModalProcuramentTerm';
import { ModalVirtualDispatcherConfirmation } from './ModalVirtualDispatcherConfirmation';

export function OrderShow() {
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const { order_id } = useParams();
  const [removeCorrectionLoading, setRemoveCorrectionLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [initCorrectionErrorMessage, setInitCorrectionErrorMessage] =
    useState('');

  const [isModalQuestionFilesOpen, setIsModalQuestionFilesOpen] = useState();

  const [isModalProcuramentTermOpen, setIsModalProcuramentTermOpen] =
    useState(false);

  const [
    isModalVirtualDispatcherConfirmationOpen,
    setIsModalVirtualDispatcherConfirmationOpen,
  ] = useState(false);

  const [virtualDispatcherConfirmationObservation, setVirtualDispatcherConfirmationObservation] = useState('');
  const [virtualDispatcherConfirmationVideo, setVirtualDispatcherConfirmationVideo] = useState('');

  async function getOrder() {
    try {
      const response = await api.get(`/orders/${order_id}`);
      setOrder(response.data);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao carregar pedido');
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrder();
  }, []);

  async function unbindCorrection() {
    setRemoveCorrectionLoading(true);
    try {
      await api.post(`/corrections/unbind/${order_id}`);
      toast.success('Correção desvilculada com sucesso');
      navigate(-1);
    } catch (error) {
      toast.error('Erro ao remover correção');
      setInitCorrectionErrorMessage(error.response.data.message);
      setRemoveCorrectionLoading(false);
    }
  }

  async function approvePreAnalysis() {
    setRemoveCorrectionLoading(true);
    try {
      await api.post(`/orders/approve-pre-analysis/${order_id}`);
      toast.success('Pré-análise aprovado com sucesso');
      navigate(-1);
    } catch (error) {
      toast.error('Error ao aprovadar Pré-análise');
      setInitCorrectionErrorMessage(error.response.data.message);
      setRemoveCorrectionLoading(false);
    }
  }

  async function cancelPreAnalysis() {
    setRemoveCorrectionLoading(true);
    try {
      await api.post(`/orders/cancel-pre-analysis/${order_id}`);
      toast.success('Pré-análise cancelada com sucesso');
      navigate(-1);
    } catch (error) {
      toast.error('Error ao cancelar Pré-análise');
      setInitCorrectionErrorMessage(error.response.data.message);
      setRemoveCorrectionLoading(false);
    }
  }

  const isUserAdminMaster = useMemo(
    () => user.permission_level === 'master',
    [user],
  );

  const isCorrectionFinish = useMemo(() => {
    if (
      order &&
      order.correction_status === 'COMPLETED'
    ) {
      return true;
    }

    return false;
  }, [order, user]);

  const [allowEditCorrectionLoading, setAllowEditCorrectionLoading] = useState(false);

  async function allowEditCorrection() {
    setAllowEditCorrectionLoading(true);
    try {
      await api.post(`/corrections/allow-edit/${order_id}`);
      toast.success('Edição do recurso habilitada com sucesso');
      setAllowEditCorrectionLoading(false);
      getOrder();
    } catch (error) {
      toast.error('Erro ao habilitar edição do recurso');
      setAllowEditCorrectionLoading(false);
    }
  }

  function getPaymentMethodTag(paymentMethod) {
    if (paymentMethod && paymentMethod.toLowerCase() === 'pix') {
      return (
        <Tag color="processing">
          PIX
        </Tag>
      );
    }

    if (paymentMethod && paymentMethod.toLowerCase() === 'credit_card') {
      return (
        <Tag color="geekblue">
          Cartão de crédito
        </Tag>
      );
    }
  }

  console.log(order);

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>

        <Breadcrumb.Item key="/">Pedido</Breadcrumb.Item>
      </Breadcrumb>

      {!loading && !!order && (
        <>
          <Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Button
                style={{ marginLeft: 0, marginBottom: 15 }}
                type="link"
                icon={<MdKeyboardBackspace size={28} />}
                size={20}
                onClick={() => navigate(-1)}
              />
              <h2>Pedido</h2>
            </div>

            <div>
              {isUserAdminMaster && (
                <>
                  {
                    !!order.product.is_pre_analysis && (
                      <>
                        <Tooltip title="Aprovar pré-análise">
                          <Popconfirm
                            title="Aprovar pré-análise"
                            description="Aprovar pré-análise"
                            onConfirm={approvePreAnalysis}
                            okText="Confirmar"
                            cancelText="Não"
                            loading={removeCorrectionLoading}
                          >
                            <Button
                              style={{ background: 'green' }}
                              icon={<MdApproval />}
                              type="primary"
                            >
                              Aprovar
                            </Button>
                          </Popconfirm>
                        </Tooltip>

                        <Tooltip title="Pré-análise Incabível">
                          <Popconfirm
                            title="Pré-análise Incabível"
                            description="Pré-análise Incabível"
                            onConfirm={cancelPreAnalysis}
                            okText="Confirmar"
                            cancelText="Não"
                            loading={removeCorrectionLoading}
                          >
                            <Button
                              style={{ background: '#D4380D' }}
                              icon={<MdApproval />}
                              type="primary"
                            >
                              Incabível
                            </Button>
                          </Popconfirm>
                        </Tooltip>

                      </>
                    )
                  }

                  <Tooltip title="Remover recurso do especialista">
                    <Popconfirm
                      title="Remover recurso do especialista"
                      description="Ao remover recurso, o pedido estará livre para outros especialistas"
                      onConfirm={unbindCorrection}
                      okText="Confirmar"
                      cancelText="Não"
                      loading={removeCorrectionLoading}
                    >
                      <Button
                        style={{ background: '#D4380D' }}
                        icon={<RollbackOutlined />}
                        type="primary"
                      >
                        Desvilcular recurso
                      </Button>
                    </Popconfirm>
                  </Tooltip>

                </>

              )}

              {
                isUserAdminMaster && isCorrectionFinish && (
                  <Popconfirm
                    title="Habilitar edição do recurso"
                    description="Tem certeza que deseja habilitar a edição do recurso ?"
                    onConfirm={allowEditCorrection}
                    okText="Confirmar"
                    cancelText="Não"
                    loading={allowEditCorrectionLoading}
                  >
                    <Button
                      style={{ marginTop: 20, marginBottom: 20, marginLeft: 20, background: 'red' }}
                      type="primary"
                      loading={allowEditCorrectionLoading}
                    >
                      Editar recurso
                    </Button>
                  </Popconfirm>
                )
              }
            </div>
          </Title>

          {initCorrectionErrorMessage && (
            <Alert
              message="Erro ao iniciar correção"
              description={initCorrectionErrorMessage}
              type="error"
              showIcon
              style={{ marginBottom: 30 }}
            />
          )}

          {isUserAdminMaster && (
            <Descriptions
              style={{ marginBottom: 50 }}
              title="Informações do Pedido"
              bordered
            >
              <Descriptions.Item label="Id do Serviço" span={2}>
                {order.product.code}
              </Descriptions.Item>
              <Descriptions.Item label="Serviço" span={2}>
                {order.product.name}
              </Descriptions.Item>
              <Descriptions.Item label="N° pedido" span={2}>
                {order.order_number}
              </Descriptions.Item>
              <Descriptions.Item label="Método de Pagamento" span={2}>
                {getPaymentMethodTag(order.payment_method)}
              </Descriptions.Item>
              <Descriptions.Item label="Status da correção" span={2}>
                <Tag
                  color={getOrderCorrectionStatusColor(order.correction_status)}
                  key="nice"
                >
                  {getOrderCorrectionStatus(order.correction_status)}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Data do Pedido" span={2}>
                {format(parseISO(order.created_at), 'dd/MM/yyyy - H:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="Data do Pagamento" span={2}>
                {order.payment_cofirm_at && format(parseISO(order.payment_cofirm_at), 'dd/MM/yyyy - H:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="Valor" span={2}>
                {currencyFormatter.format(order.total_value / 100, {
                  code: 'BRL',
                })}
              </Descriptions.Item>
              <Descriptions.Item label="Status de Pagamento" span={2}>
                <Tag
                  color={getOrderPaymentStatusColor(order.payment_status)}
                  key="nice"
                >
                  {getOrderPaymentStatus(order.payment_status)}
                </Tag>

                <Tag
                  color={getOrderPaymentStatusColor(order.payment_status_two)}
                  key="nice"
                >
                  {getOrderPaymentStatus(order.payment_status_two)}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          )}

          {isUserAdminMaster && order.dispatcher_virtual && (
            <Descriptions
              style={{ marginBottom: 50 }}
              title="Contratação Despachante Virtual"
              bordered
              extra={(
                <Button
                  type="primary"
                  onClick={() =>
                    setIsModalVirtualDispatcherConfirmationOpen(true)}
                >
                  Confirmar envio
                </Button>
              )}
            >
              <Descriptions.Item label="Id do Serviço" span={2}>
                {order.dispatcher_virtual.product.code}
              </Descriptions.Item>
              <Descriptions.Item label="Serviço" span={2}>
                {order.dispatcher_virtual.product.name}
              </Descriptions.Item>
              <Descriptions.Item label="N° pedido" span={2}>
                {order.dispatcher_virtual.order_number}
              </Descriptions.Item>
              <Descriptions.Item label="Método de Pagamento" span={2}>
                {getPaymentMethodTag(order.dispatcher_virtual.payment_method)}
              </Descriptions.Item>
              <Descriptions.Item label="Data do Pedido" span={2}>
                {format(
                  parseISO(order.dispatcher_virtual.created_at),
                  'dd/MM/yyyy - H:mm:ss',
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Valor" span={2}>
                {currencyFormatter.format(
                  order.dispatcher_virtual.total_value / 100,
                  {
                    code: 'BRL',
                  },
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Status de Pagamento" span={2}>
                <Tag
                  color={getOrderPaymentStatusColor(
                    order.dispatcher_virtual.payment_status,
                  )}
                  key="nice"
                >
                  {getOrderPaymentStatus(
                    order.dispatcher_virtual.payment_status,
                  )}
                </Tag>
              </Descriptions.Item>

              <Descriptions.Item label="Confirmação de envio: Vídeo" span={2}>
                <Button
                  type="link"
                  onClick={() =>
                    setIsModalVirtualDispatcherConfirmationOpen(true)}
                >
                  Visualizar
                </Button>
              </Descriptions.Item>

              <Descriptions.Item
                label="Confirmação de envio: Observação"
                span={2}
              >
                asdasd
              </Descriptions.Item>
            </Descriptions>
          )}

          {!isUserAdminMaster && (
            <Descriptions
              style={{ marginBottom: 50 }}
              title="Informações do Pedido"
              bordered
            >
              <Descriptions.Item label="Id do Serviço" span={2}>
                {order.product.code}
              </Descriptions.Item>
              <Descriptions.Item label="Serviço" span={2}>
                {order.product.name}
              </Descriptions.Item>
              <Descriptions.Item label="N° pedido" span={2}>
                {order.order_number}
              </Descriptions.Item>
              <Descriptions.Item label="Status da correção" span={2}>
                <Tag
                  color={getOrderCorrectionStatusColor(order.correction_status)}
                  key="nice"
                >
                  {getOrderCorrectionStatus(order.correction_status)}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Data de criação" span={2}>
                {format(parseISO(order.created_at), 'dd/MM/yyyy - H:mm:ss')}
              </Descriptions.Item>
            </Descriptions>
          )}

          {isUserAdminMaster && (
            <Descriptions
              style={{ marginBottom: 50 }}
              title="Informações do Usuário"
              bordered
            >
              <Descriptions.Item label="Nome" span={2}>
                {order.client.name}
              </Descriptions.Item>
              <Descriptions.Item label="CPF" span={2}>
                {order.client.cpf}
              </Descriptions.Item>
              <Descriptions.Item label="E-mail" span={2}>
                {order.client.email}
              </Descriptions.Item>
              <Descriptions.Item label="Número de telefone" span={2}>
                {order.client.phone_number}
              </Descriptions.Item>
            </Descriptions>
          )}

          <Descriptions
            style={{ marginBottom: 50 }}
            title="Informações Adicionais"
            bordered
          >
            <Descriptions.Item label="Observação do Cliente" span={2}>
              {order.client_observation || 'Nenhuma observação'}
            </Descriptions.Item>

            <Descriptions.Item label="Arquivos" span={2}>
              <Button
                type="link"
                onClick={() => setIsModalQuestionFilesOpen(true)}
              >
                Visualizar
              </Button>
            </Descriptions.Item>
          </Descriptions>

          {isUserAdminMaster && (
            <Descriptions
              style={{ marginBottom: 50 }}
              title="Informações da Correção"
              bordered
            >
              <Descriptions.Item label="Coordenador" span={2}>
                {order.correction &&
                  order.correction.coordinator &&
                  order.correction.coordinator.name}
              </Descriptions.Item>
              <Descriptions.Item label="Especialista" span={2}>
                {order.correction &&
                  order.correction.proofreader &&
                  order.correction.proofreader.name}
              </Descriptions.Item>
            </Descriptions>
          )}

          {order.procurement_virtual && (
            <Descriptions
              style={{ marginBottom: 50 }}
              title="Procuração virtual"
              bordered
            >
              <Descriptions.Item label="Nome" span={2}>
                {order.procurement_virtual.name}
              </Descriptions.Item>
              <Descriptions.Item label="CPF" span={2}>
                {order.procurement_virtual.cpf}
              </Descriptions.Item>
              <Descriptions.Item label="Endereço" span={2}>
                {order.procurement_virtual.andress}
              </Descriptions.Item>
              <Descriptions.Item label="Login" span={2}>
                {order.procurement_virtual.login}
              </Descriptions.Item>
              <Descriptions.Item label="Senha" span={2}>
                {order.procurement_virtual.password}
              </Descriptions.Item>
              <Descriptions.Item label="Termo de uso" span={2}>
                <Button
                  type="link"
                  onClick={() => setIsModalProcuramentTermOpen(true)}
                >
                  Visualizar
                </Button>
              </Descriptions.Item>
            </Descriptions>
          )}

          <Descriptions
            style={{ marginBottom: 50 }}
            title="Histórico de envio de recurso"
            bordered
          >
            {
              order.resource_submission_history.length && (
                order.resource_submission_history.map(historyItem => (
                  <Descriptions.Item label="Enviado em" span={3}>
                    {format(parseISO(historyItem.created_at), 'dd/MM/yyyy - H:mm:ss')}
                  </Descriptions.Item>
                ))

              )
            }

            {
              !order.resource_submission_history.length && (
                <span style={{ textAlign: 'center' }}> Sem histórico </span>
              )
            }

          </Descriptions>

          <ModalQuestionFiles
            isModalQuestionFilesOpen={isModalQuestionFilesOpen}
            setIsModalQuestionFilesOpen={setIsModalQuestionFilesOpen}
            productQuestions={order.product_questions}
            productQuestionFilesWithoutQuestion={
              order.product_question_files_without_question
            }
          />

          {order.procurement_virtual && (
            <ModalProcuramentTerm
              isModalProcuramentTermOpen={isModalProcuramentTermOpen}
              setIsModalProcuramentTermOpen={setIsModalProcuramentTermOpen}
              procuramentVirtual={order.procurement_virtual}
            />
          )}
        </>
      )}

      {loading && <Loading />}

      <ModalVirtualDispatcherConfirmation
        isModalVirtualDispatcherConfirmationOpen={
          isModalVirtualDispatcherConfirmationOpen
        }
        setIsModalVirtualDispatcherConfirmationOpen={
          setIsModalVirtualDispatcherConfirmationOpen
        }
        virtualDispatcherConfirmationObservation={virtualDispatcherConfirmationObservation}
        setVirtualDispatcherConfirmationObservation={setVirtualDispatcherConfirmationObservation}
        virtualDispatcherConfirmationVideo={virtualDispatcherConfirmationVideo}
        setVirtualDispatcherConfirmationVideo={setVirtualDispatcherConfirmationVideo}
      />
    </Container>
  );
}
