import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
`;

export const BoxForm = styled.div`
  background: #fff;
  padding: 40px 30px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px 2px;
  min-height: 200px;

  h2 {
    font-size: 18px;
    margin-bottom: 40px;
  }

  &FormButton {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BoxInput = styled.div`
  display: flex;
  margin-bottom: 20px;

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    margin-right: 20px;
    width: 150px;

    span {
      margin-right: 7px;
    }
  }

  :global(#background) {
    display: none;
  }

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 7px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 300px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }

  textarea {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 600px;
    height: 150px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

export const ResourceList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  li {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .image {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 48px;
      height: 48px;
      line-height: 60px;
      text-align: center;
      flex: none;

      img {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }

    .link {
      margin-left: 15px;
      flex: 1;
    }

    strong {
      flex: 1;
    }
  }
`;

export const UploadButton = styled.label`
  border-radius: 50%;
  width: 102px;
  height: 102px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 10px;
    }
  }
`;

export const ProductImages = styled.div`
  > div {
    margin-bottom: 40px;

    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const IconImage = styled.div`
  width: 54px;
  height: 54px;

  img {
    width: 100%;
  }
`;
export const LabelButton = styled.label`
  background-color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  font-size: 14px;
  width: 150px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  color: rgba(0, 0, 0, 0.88);
  border: 1px solid #d9d9d9;
`;

export const ProductQuestions = styled.div`
  width: 100%;

  > div {
    border-block-end: 1px solid rgba(5, 5, 5, 0.06);
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    .content {
      strong {
        color: rgba(0, 0, 0, 0.88);
        margin-bottom: 8px;
        display: inline-block;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 25px;
      }
    }

    .actions {
      button {
        margin-left: 15px;
      }
    }
  }
`;

export const TextEditorBox = styled.div`
  width: 100%;
  max-width: 700px;

  .ql-editor {
    min-height: 300px;
  }
`;
export const DateInputBox = styled.div`
  margin-right: 15px;
  margin-bottom: 30px;

  label {
    margin-right: 10px;
    font-size: 14px;
    width: 120px !important;
    max-width: 100%;
    display: inline-flex;
    margin-right: 20px;
  }

  input {
    width: 300px;
    height: 38px;
    background-color: #ffffff;
    border: 1px solid rgb(217, 217, 217);
    padding: 4px 15px;
    border-radius: 6px;
    font-size: 14px;
  }
`;
