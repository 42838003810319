/* eslint-disable prettier/prettier */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Empty } from 'antd';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import currencyFormatter from 'currency-formatter';

import { Container } from './styles';
import { PageTitle } from '../../components/PageTitle';

import { api } from '../../services/api';
import { Filters } from './components/Filters';
import { Loading } from '../../components/Loading';
import { AdminOrdersTable } from './components/AdminOrdersTable';
import { useAuth } from '../../hooks/AuthContext';
import { OrdersTable } from './components/OrdersTable';
import { ModalOrderCancel } from './components/ModalOrderCancel';
import { ProofreaderOrdersTable } from './components/ProofreaderOrdersTable';
import { ModalStartCorrection } from './components/ModalStartCorrection';

export function Orders() {
  const { user } = useAuth();

  const [orders, setOrders] = useState([]);
  const [ordersTotalValue, setOrdersTotalValue] = useState(0);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const [ordersTotalSearch, setOrdersTotalSearch] = useState(0);
  const [allOrdersTotalValue, setAllOrdersTotalValue] = useState(0);
  const [loading, setLoading] = useState(true);

  // Filters
  const [filterCorrectionStatus, setFilterCorrectionStatus] = useState('');
  const [filterPaymentStatus, setFilterPaymentStatus] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  // Aprova pagamento
  const [showModalApprovePayment, setShowModalApprovePayment] = useState(false);

  const [paginationPage, setPaginationPage] = useState(1);
  const [searchPaginationPage, setSearchPaginationPage] = useState(null);

  // Modal Order Cancel
  const [isOpenModalOrderCancel, setIsOpenModalOrderCancel] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState();

  // Modal Start Correction
  const [isOpenModalStartCorrection, setIsOpenModalStartCorrection] =
    useState(false);
  const [orderToStartCorrection, setOrderToStartCorrection] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  async function loadOrders() {
    try {
      const response = await api.get(`/orders/?page=${paginationPage}`);
      const ordersFormmated = response.data.orders.map((order, index) => ({
        ...order,
        key: index,
        product_id: order.product.code,
        product_name: order.product.name,
        order_id: order.order_number,
        payment_status: [order.payment_status],
        payment_status_two: [order.payment_status_two],
        correction_status: [order.correction_status],
        client: order.client,
        createdFormatted: format(
          parseISO(order.created_at),
          'dd/MM/yyyy - H:mm:ss',
        ),
        total_value_formatted: currencyFormatter.format(
          order.total_value / 100,
          {
            code: 'BRL',
          },
        ),
        product_name_formated:
          order.product.name.length > 25
            ? `${order.product.name.substring(0, 25)}...`
            : order.product.name,
        have_virtual_dispatcher: order.order_items && order.order_items.length,
      }));

      const totalValue = response.data.orders.reduce(
        (currentValue, nextValue) => currentValue + nextValue.total_value / 100,
        0,
      );
      setOrdersTotal(response.data.total_orders);
      setAllOrdersTotalValue(response.data.total_orders_value / 100);

      setOrders(ordersFormmated);
      setOrdersTotalValue(totalValue);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao carregar pedidos');
      setLoading(false);
    }
  }

  useEffect(() => {
    loadOrders();
  }, [paginationPage]);

  useEffect(() => {
    loadOrders();
  }, []);

  const [checkedFilters, setCheckedFilters] = useState([]);

  const searchOrders = useCallback(async () => {
    try {
      setLoading(true);
      setIsSearching(true);

      console.log('checkedFilters', checkedFilters);

      const have_virtual_dispatcher = checkedFilters.some(
        (filterItem) => filterItem === 'have_virtual_dispatcher',
      );

      const is_pre_analysis = checkedFilters.some(
        (filterItem) => filterItem === 'is_pre_analysis',
      );

      const response = await api.get(
        `/orders/admin/search/?search_word=${searchWord}&correction_status=${filterCorrectionStatus}&payment_status=${filterPaymentStatus}&start_date=${startDate && format(startDate, 'yyyy-MM-dd')}&end_date=${endDate && format(endDate, 'yyyy-MM-dd')}&have_virtual_dispatcher=${have_virtual_dispatcher}&is_pre_analysis=${is_pre_analysis}&page=${searchPaginationPage || 1}`,
      );

      const ordersFormmated = response.data.map((order, index) => ({
        ...order,
        key: index,
        product_id: order.product.code,
        product_name: order.product.name,
        order_id: order.order_number,
        payment_status: [order.payment_status],
        correction_status: [order.correction_status],
        payment_status_two: [order.payment_status_two],
        client: order.client,
        createdFormatted: format(
          parseISO(order.created_at),
          'dd/MM/yyyy - H:mm:ss',
        ),
        total_value_formatted: currencyFormatter.format(
          order.total_value / 100,
          {
            code: 'BRL',
          },
        ),
        product_name_formated:
          order.product.name.length > 25
            ? `${order.product.name.substring(0, 25)}...`
            : order.product.name,
        have_virtual_dispatcher: order.order_items.length,
      }));

      const totalValue = response.data.reduce(
        (currentValue, nextValue) => currentValue + nextValue.total_value / 100,
        0,
      );

      setOrders(ordersFormmated);
      setOrdersTotalSearch(response.data.length);
      setAllOrdersTotalValue(totalValue);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsSearching(false);
      console.log(error);
      toast.error('Erro ao buscar usuários');
    }
  }, [
    filterCorrectionStatus,
    filterPaymentStatus,
    searchWord,
    startDate,
    endDate,
    checkedFilters,
    searchPaginationPage,
  ]);

  useEffect(() => {
    if (searchPaginationPage !== null && searchPaginationPage !== undefined) {
      searchOrders();
    }
  }, [searchPaginationPage]);

  function clearSearch() {
    setFilterCorrectionStatus('');
    setFilterPaymentStatus('');
    setSearchWord('');
    setIsSearching(false);
    setStartDate();
    setEndDate();
    loadOrders();
    setCheckedFilters([]);
    setPaginationPage(1);
  }

  const isRenderAdminOrderTable = useMemo(
    () => !loading && !!orders.length && user.permission_level === 'master',
    [user, orders, loading],
  );

  const isRenderCoordinatorOrderTable = useMemo(
    () => user.permission_level === 'coordinator',
    [(user, orders, loading)],
  );

  const isRenderProofreaderOrderTable = useMemo(
    () => user.permission_level === 'proofreader',
    [(user, orders, loading)],
  );

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title="Pedidos" description="Todos os pedidos da loja" />

      <Filters
        filterCorrectionStatus={filterCorrectionStatus}
        setFilterCorrectionStatus={setFilterCorrectionStatus}
        filterPaymentStatus={filterPaymentStatus}
        setFilterPaymentStatus={setFilterPaymentStatus}
        setSearchWord={setSearchWord}
        clearSearch={clearSearch}
        search={searchOrders}
        searchWord={searchWord}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        checkedFilters={checkedFilters}
        setCheckedFilters={setCheckedFilters}
      />

      {!loading && !!orders.length && (
        <>
          {isRenderAdminOrderTable && (
            <AdminOrdersTable
              orders={orders}
              loading={loading}
              showModalApprovePayment={showModalApprovePayment}
              setShowModalApprovePayment={setShowModalApprovePayment}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
              searchPaginationPage={searchPaginationPage}
              setSearchPaginationPage={setSearchPaginationPage}
              ordersTotal={isSearching ? ordersTotalSearch : ordersTotal}
              isSearching={isSearching}
              ordersTotalValue={ordersTotalValue}
              setIsOpenModalOrderCancel={setIsOpenModalOrderCancel}
              setOrderToCancel={setOrderToCancel}
              allOrdersTotalValue={allOrdersTotalValue}
            />
          )}

          {isRenderCoordinatorOrderTable && (
            <OrdersTable
              orders={orders}
              loading={loading}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
              ordersTotal={isSearching ? orders.length : ordersTotal}
              isSearching={isSearching}
              searchPaginationPage={searchPaginationPage}
              setSearchPaginationPage={setSearchPaginationPage}
            />
          )}

          {isRenderProofreaderOrderTable && (
            <ProofreaderOrdersTable
              orders={orders}
              loading={loading}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
              ordersTotal={isSearching ? orders.length : ordersTotal}
              isSearching={isSearching}
              setIsOpenModalStartCorrection={setIsOpenModalStartCorrection}
              setOrderToStartCorrection={setOrderToStartCorrection}
              searchPaginationPage={searchPaginationPage}
              setSearchPaginationPage={setSearchPaginationPage}
            />
          )}
        </>
      )}

      {!loading && !orders.length && (
        <Empty
          description="Nenhum produto foi encontrado"
          style={{ marginTop: '150px' }}
        />
      )}

      {loading && <Loading />}

      <ModalOrderCancel
        isOpenModalOrderCancel={isOpenModalOrderCancel}
        setIsOpenModalOrderCancel={setIsOpenModalOrderCancel}
        orderToCancel={orderToCancel}
      />

      <ModalStartCorrection
        isOpenModalStartCorrection={isOpenModalStartCorrection}
        setIsOpenModalStartCorrection={setIsOpenModalStartCorrection}
        orderToStartCorrection={orderToStartCorrection}
      />
    </Container>
  );
}
